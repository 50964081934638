import { Injectable, inject } from "@angular/core";
import { BaseService } from "./base.service";
import { HttpRestService } from "./http-client/http-rest-client.service";
import { ModelList } from "../models/models-list";
import { OurGoalModel } from "../models/admin-settings/our-goal.model";
import { MyGoalModel } from "../models/my-goal.model";

@Injectable()
export class GoalService extends BaseService {
    private restService = inject(HttpRestService);

    constructor() {
        super();
    }

    public async getUserProgress(): Promise<number> {
        return await this.handleResponse(this.restService.get({
            url: `/goal/get-user-progress/`
        }));
    }

    public async deleteOurGoal(id: number): Promise<void> {
        await this.handleResponse(this.restService.get({
            url: `/goal/delete-our-goal/` + id
        }));
    }

    public async saveOurGoal(model: OurGoalModel): Promise<void> {
        await this.handleResponse(this.restService.post({
            url: `/goal/save-our-goal/`,
            data: model
        }));
    }

    public async getOurGoals(): Promise<ModelList<OurGoalModel>> {
        const data = await this.handleResponse(this.restService.get({
            url: `/goal/get-our-goals/`
        }));

        const items = data.items.map(x => new OurGoalModel(x));
        return new ModelList<OurGoalModel>(items, data.totalCount);
    }

    public async getTop5MyGoals(): Promise<MyGoalModel[]> {
        const data = await this.handleResponse(this.restService.get({
            url: `/goal/get-top-5-my-goals/`
        }));

        return data.map(x => new MyGoalModel(x));
    }

    public async getGoal(id: number): Promise<OurGoalModel> {
        const data = await this.handleResponse(this.restService.get({
            url: `/get/get-our-goal/` + id
        }));

        return new OurGoalModel(data);
    }

    public async getMyGoals(): Promise<ModelList<MyGoalModel>> {
        const data = await this.handleResponse(this.restService.get({
            url: `/goal/get-my-goals/`
        }));

        const items = data.items.map(x => new MyGoalModel(x));
        return new ModelList<MyGoalModel>(items, data.totalCount);
    }

    public async getMyGoal(): Promise<number> {
        return await this.handleResponse(this.restService.get({
            url: `/goal/get-my-goal/`
        }));
    }

    public async saveMyGoal(model: MyGoalModel): Promise<void> {
        await this.handleResponse(this.restService.post({
            url: `/goal/save-my-goal/`,
            data: model
        }));
    }

    public async deleteMyGoal(id: number): Promise<void> {
        await this.handleResponse(this.restService.get({
            url: `/goal/delete-my-goal/` + id
        }));
    }
}
